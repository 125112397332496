import { Component } from '@angular/core';
import { SanitizePipe } from '@hae/utils';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'hae-ui-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  standalone: true,
  imports: [TranslateModule, SanitizePipe],
})
export class FooterComponent {}
