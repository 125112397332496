/**
 * Donor360-qnr API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

export interface SubmissionError {
  id?: string;
}

export type SubmissionErrorIdEnum =
  | 'unexpectedError'
  | 'alreadyCheckedIn'
  | 'invalidCustomer'
  | 'invalidSite'
  | 'invalidDonor'
  | 'invalidLocale'
  | 'expired'
  | 'invalidStation'
  | 'dueInterviewsChanged'
  | 'checkinFailed'
  | 'unableToDetermineVisit'
  | 'interviewAcknowledgementExpired';
export const SubmissionErrorIdEnum = {
  UnexpectedError: 'unexpectedError' as SubmissionErrorIdEnum,
  AlreadyCheckedIn: 'alreadyCheckedIn' as SubmissionErrorIdEnum,
  InvalidCustomer: 'invalidCustomer' as SubmissionErrorIdEnum,
  InvalidSite: 'invalidSite' as SubmissionErrorIdEnum,
  InvalidDonor: 'invalidDonor' as SubmissionErrorIdEnum,
  InvalidLocale: 'invalidLocale' as SubmissionErrorIdEnum,
  Expired: 'expired' as SubmissionErrorIdEnum,
  InvalidStation: 'invalidStation' as SubmissionErrorIdEnum,
  DueInterviewsChanged: 'dueInterviewsChanged' as SubmissionErrorIdEnum,
  CheckinFailed: 'checkinFailed' as SubmissionErrorIdEnum,
  UnableToDetermineVisit: 'unableToDetermineVisit' as SubmissionErrorIdEnum,
  InterviewAcknowledgementExpired:
    'interviewAcknowledgementExpired' as SubmissionErrorIdEnum,
};

export const SubmissionErrorCodeEnum = {
  UnexpectedErrorCode: '100',
  AlreadyCheckedInCode: '101',
  InvalidCustomerCode: '102',
  InvalidSiteCode: '103',
  InvalidDonorCode: '104',
  InvalidLocaleCode: '105',
  ExpiredCode: '106',
  DueInterviewsChangedCode: '107',
  UnableToDetermineVisitCode: '108',
  InterviewAcknowledgementExpiredCode: '109',
};
