import { BehaviorSubject, Observable } from 'rxjs';

export abstract class AbstractStateService<T> {
  protected state!: BehaviorSubject<T>;

  protected initialState!: T;

  protected stateName!: string;

  setState(value: Partial<T>): void {
    const temp = this.state.getValue();
    this.state.next({ ...temp, ...value });
  }

  removeState(): void {
    this.state.complete();
  }

  stateSnapshot(): T {
    return this.state.getValue();
  }

  getState(): Observable<T> {
    return this.state.asObservable();
  }

  logState(): void {
    // eslint-disable-next-line no-console
    console.log(
      `%cSTATE: ${this.stateName}`,
      'background: #222; color: #bada55',
    );
    // eslint-disable-next-line no-console
    console.dir(this.state.getValue());
  }

  resetState(): void {
    this.state.next(this.initialState);
  }
}
