import { Injectable } from '@angular/core';
import { LoginRequestDto, ScannerService, TokenResponseDto } from '@hae/api';
import { AbstractStateService, MetaService, Token } from '@hae/utils';
import {
  CognitoAccessToken,
  CognitoIdToken,
  CognitoRefreshToken,
  CognitoUserSession,
  ICognitoUserSessionData,
} from 'amazon-cognito-identity-js';
import {
  BehaviorSubject, Observable, of, Subscription, timer,
} from 'rxjs';
import {
  catchError,
  filter,
  map,
  mergeMap,
  skipWhile,
  take,
} from 'rxjs/operators';

interface Data {
  token?: TokenResponseDto;
  siteId: string;
  stationId: string;
  stationName: string;
}

@Injectable({ providedIn: 'root' })
export class AuthStateService extends AbstractStateService<Data> {
  protected stateName = 'Data';

  protected initialState: Data = {
    siteId: '',
    stationId: '',
    stationName: '',
  };

  protected state = new BehaviorSubject(this.initialState);

  private _sessionExpired = false;

  private session$ = new BehaviorSubject<CognitoUserSession | null | undefined>(
    undefined,
  );

  private storageKey = '';

  private refreshTimeout?: Subscription;

  constructor(
    private scannerService: ScannerService,
    private metaService: MetaService,
  ) {
    super();
  }

  setState(value: Partial<Data>): void {
    super.setState(value);
    this.metaService.set(this.storageKey, this.stateSnapshot());
  }

  resetState(): void {
    super.resetState();
    this.metaService.set(this.storageKey, this.stateSnapshot());
  }

  get sessionExpired(): Readonly<boolean> {
    return this._sessionExpired;
  }

  get currentSiteId$(): Observable<string> {
    return this.getState().pipe(map(({ siteId }) => siteId));
  }

  get currentStationId$(): Observable<string> {
    return this.getState().pipe(map(({ stationId }) => stationId));
  }

  get currentStationName$(): Observable<string> {
    return this.getState().pipe(map(({ stationName }) => stationName));
  }

  initialize(storageKey: string): void {
    this.storageKey = storageKey;
    const data = this.metaService.get<Data | undefined>(storageKey);
    if (data) {
      this.setState(data);
      if (data.token) {
        this.setToken(data.token);
        return;
      }
    }
    this.session$.next(null);
  }

  destroy(): void {
    if (this.refreshTimeout) {
      this.refreshTimeout.unsubscribe();
    }
    super.resetState();
  }

  login(loginRequest: LoginRequestDto): Observable<void> {
    return this.scannerService.nexlynkLogin(loginRequest).pipe(
      map(({ payload }) => {
        this.setToken(payload);
      }),
    );
  }

  isLoggedIn(): Observable<boolean> {
    return this.session$.pipe(
      skipWhile((session) => session === undefined),
      map((session) => Boolean(session)),
    );
  }

  getJWT(): Observable<null | string> {
    return this.session$.pipe(
      mergeMap((session) => {
        if (!session) {
          return of(null);
        }
        if (!session.isValid()) {
          return this.refreshToken(session.getRefreshToken().getToken());
        }
        return of(session);
      }),
      map((session) => {
        if (!session) {
          return null;
        }
        return session.getAccessToken().getJwtToken();
      }),
      take(1),
    );
  }

  logout(wasSessionExpired = false): void {
    this.isLoggedIn()
      .pipe(take(1))
      .subscribe((loggedIn) => {
        if (loggedIn) {
          this._sessionExpired = wasSessionExpired;
        }
        this.session$.next(null);
        this.resetState();
      });
  }

  private setToken(token: TokenResponseDto): void {
    const session = this.getUserSession(token);
    if (!session) {
      this.refreshToken(token.refreshToken).subscribe();
    } else {
      this.setActualSession(session, token);
    }
  }

  private getUserSession(token: Token): CognitoUserSession | null {
    const session = new CognitoUserSession({
      IdToken: new CognitoIdToken({ IdToken: token.idToken }),
      AccessToken: new CognitoAccessToken({ AccessToken: token.accessToken }),
      RefreshToken: new CognitoRefreshToken({ RefreshToken: token.refreshToken }),
      ClockDrift: 0,
    } as ICognitoUserSessionData);

    if (session.isValid()) {
      return session;
    }

    return null;
  }

  private startRefreshTimeout(accessTokenExpiry: number): void {
    if (this.refreshTimeout) {
      this.refreshTimeout.unsubscribe();
    }

    this.refreshTimeout = timer(new Date(accessTokenExpiry * 1000))
      .pipe(
        map(() => this.session$.getValue()),
        filter((session): session is CognitoUserSession => Boolean(session)),
        mergeMap((session) => this.refreshToken(session.getRefreshToken().getToken())),
      )
      .subscribe();
  }

  private setActualSession(session: CognitoUserSession, token: Token): void {
    this.startRefreshTimeout(session.getAccessToken().getExpiration());
    this.setState({ token });
    this.session$.next(session);
  }

  private refreshToken(
    refreshToken: string,
  ): Observable<CognitoUserSession | null> {
    return this.scannerService.refreshToken({ refreshToken }).pipe(
      map(({ payload }) => {
        const session = this.getUserSession(payload);
        if (!session) {
          return null;
        }
        this.setActualSession(session, payload);
        return session;
      }),
      catchError(() => {
        this.session$.next(null);
        this.resetState();
        return of(null);
      }),
    );
  }
}
